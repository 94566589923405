<template>
    <div class="contain">
        
        <Header :config="config"  />

        <div class="main-container">
            
            <sidebar  />

            <div class="main-content">
 
                <div class="main-page" >
                    <div class="channel-container">
                        
                        <div v-html="about.content" ></div>
                    </div>
                    
                </div>
                
            </div>
        </div>
    </div>
</template>


<script>

import Vue from 'vue'
import {VueMasonryPlugin} from 'vue-masonry';
Vue.use(VueMasonryPlugin)


import Header from '@/components/Header/header.vue';
import sidebar from '@/components/sidebar/sidebar.vue';
import { apiPolicyAgreement} from '@/api/app'
import { mapGetters, mapActions } from 'vuex'
export default {
    name: 'about',
    components: {
        sidebar,
        Header
    },

    data() {
        return {
            about: ''
        }
    },

    computed: {
        ...mapGetters(['config']),
    },

    async created() {
        this.getAbout()
    },

    methods: {
        
        getAbout(){

            apiPolicyAgreement({
                type:3,
            }).then(data => {
             
                this.about = data
            }).catch(err => {
                
            })
        },


    },
}
</script>


<style lang="scss" scoped>

 
    .contain{
        width:1700px;
        margin: 0 auto;
    }
    .main-container{
        display: flex;
    }
    .main-content{
        width: 100%;
        padding-left: 282.66667px;
        .main-page{
            padding:85px 0 20px 35px;
        }
        
        
    }

</style>
